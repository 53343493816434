/* App container */
.app {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Navigation styles */
.nav {
    display: flex;
    gap: 1rem;
}

.nav a {
    text-decoration: none;
    color: #333;
    font-size: 1.1rem;
    font-weight: bold;
}

.nav a:hover {
    color: #666;
}

/* Footer styles */
footer {
    padding: 1rem;
    background-color: #f8f8f8;
    text-align: center;
}

.footer-text {
    font-size: 0.9rem;
    color: #666;
}

body {
    font-family: 'Open Sans', sans-serif;
}